<script>

 import { accountsService } from '../../../../services/accounts.service';

export default {
  data() {
    return {
      balanceData:{},
      balData:[],
      businessConditions: JSON.parse(localStorage.getItem("businessConditions"))
    };
  },

  created() {
    this.getAccountBalances()
  },

  methods:{
    async getAccountBalances() {
        try {
        await accountsService.getUserAccountBalance(JSON.parse(localStorage.getItem('user')).id).then(response=>{
            if(response.status ==="SUCCESS"){
                this.balanceData = response.responseBody;
                this.loadStats();
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

    async loadStats() {
        try {
          var tempBal = []
          this.balanceData.balanceList.forEach(element => {
            var item = element.currencyType.toUpperCase() + " "+element.availableBalance;
            tempBal.push(item);
        
          });
          this.balData = tempBal;
          
        } catch (error) {
          console.log(error);
        }
    },
  }

};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Account Balances</h4>
      <div class="row mb-2">
            <p class="mb-2 font-size-17 text-truncate col-xl-4" v-for="(data, index) in balData" :key="index">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>{{data}}
            </p>
      </div>
    </div>
  </div>
</template>