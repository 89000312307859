<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { vaultService } from '../../../services/vault.service';
import FundingAcknowledgment from './acknowledgeFunding';
import AccountBalances from "../dashboard/teller-dashboard/accountBalances";

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,

} from "vuelidate/lib/validators";

const initForm = { amount: "",teller:"",narration: "", userId: JSON.parse(localStorage.getItem('user')).id, shopId: JSON.parse(localStorage.getItem('user')).shopId};

export default {
       page: {
    title: "Vault",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    FundingAcknowledgment,
    PageHeader,
    AccountBalances
},
    data() {
        return {
            tableData: [],
            title: "Vault",
            items: [
                {
                    text: "Vault Management",
                    href: "/vault"
                },
                {
                    text: "Teller Funding",
                    active: true
                }
            ],

            form: {

                shopId:JSON.parse(localStorage.getItem("user")).shopId,
                agentId: JSON.parse(localStorage.getItem("user")).id,
                transactionId:0,
                currencyType: null,
                transactionType: "initiate-close-of-till"
            },

            currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"rand",
                    currencyName: "RAND"
                },
                {
                    currencyType:"pound",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"euro",
                    currencyName: "EURO"
                }
            ],
            fundingSuccess:false,
            fundingFailed: false,

            submitted: false,
            submitform: false,
            submit: false
        };
    },

    validations: {
        form: {
            currencyType: { required },
        },
    },

    computed: {
        rows() {
        return this.tableData.length;
        },
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    methods: { 
        ...notificationMethods,

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.submitted = false;
                return;
            } else {
                const {form} = this;

                const vaultForm = {
                        "operation" : "close till",
                        "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                        "requestBody" : form
                };
                
                vaultService.tellerActivity(vaultForm).then(result=>{
                    if(result.status=="SUCCESS"){
                        this.submitted = false;
                        this.form = Object.assign({}, initForm);
                        this.agentList = []
                        this.fundingSuccess = true;
                        this.responseMessage = result.message;
                    } else {
                        this.submitted = false;
                        this.fundingFailed = true;
                        this.responseMessage = result.message;
                    }
                });
            }
        },
    },

};

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class=" col-md-4" >
                <AccountBalances />
                <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <h5>Till Closure</h5>
                            </div>
                        </div>
                    </div>

                    <div class="no-gutters">

                        <p>Liquidation will transfer all money in your till back to the branch vault.</p>
                        <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="fundingFailed">
                            <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
                        </h5>
                        <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="fundingSuccess">
                            <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
                        </h5>
                        <form class="needs-registration" @submit.prevent="formSubmit">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Currency</label>
                                            <select class="form-control" v-model="form.currencyType" :class="{ 'is-invalid': submitted && $v.form.currencyType.$error }">
                                                <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                            </select>
                                            <div v-if="submitted && $v.form.currencyType.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.currencyType.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="row">
                                <div class="col-md-6" v-if="!submitted">
                                    <button class=" btn btn-primary" type="submit">Close {{form.currencyType}} Till</button>
                                </div>
                                <div class="col-md-6">
                                    <div class="spinner" v-if="submitted">
                                        <i class="ri-loader-line spin-icon"></i>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </form>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <FundingAcknowledgment/>
            </div>
        </div>
    </Layout>
</template>